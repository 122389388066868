import React from 'react';
import Styled from 'styled-components'
import Delivery from '../Assets/Delivery.jpg';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';


const Terms = () => {

const StyledImage = Styled.img`
height:500px;
width: 500px;
padding:100px;
`;

const StyledP = Styled.p`
font-size:5rem;
font-weight:800;
font-family: EB Garamond;
color:#055d6b;
padding:150px;

`;

const StyledP1 = Styled.article`
font-size:3rem;
font-weight:400;
font-family: EB Garamond;
color:#000000;
padding:15px;


`;



return(<Paper>
     <Stack direction="row"  divider={<Divider sx={{color:'#055d6b'}}orientation="vertical" flexItem />} spacing={20}>
        <StyledImage src = {Delivery} alt = "delivery"></StyledImage>
        <StyledP>Delivery Instructions</StyledP>
       
      </Stack>
      <Paper>
       <StyledP1>
      <b> Delivering Timeless Elegance to Your Doorstep</b>

      <StyledP1>At Creative Antiqueology, we understand the value of owning antiques that tell stories of artistry, craftsmanship, and history. Whether you're purchasing exquisite brass and bronze artifacts, elegant furniture, or captivating paintings, our goal is to ensure that every piece arrives at your home with the care and respect it deserves.</StyledP1>

<b>Why Choose Us for Antique Delivery?</b>
<b>Safe and Secure Packaging:</b>
<StyledP1>Each antique is unique and irreplaceable. We use custom packaging materials and techniques to safeguard your treasures during transit.</StyledP1>

<b>Specialized Handling:</b>
<StyledP1>Our team of trained professionals understands the delicate nature of antiques, ensuring they are handled with precision and care every step of the way.</StyledP1>

<b>Timely and Reliable Service:</b>
<StyledP1>From small keepsakes to larger pieces of furniture, we coordinate efficient delivery so your purchases arrive on time and in pristine condition.</StyledP1>

<b>Global Reach:</b>
<StyledP1>Whether you're located nearby or halfway around the world, our trusted delivery network ensures your antiques reach you wherever you are.</StyledP1>

<b>Customer Support:</b>
<StyledP1>From the moment you place your order to the time it’s delivered, our team is here to address any concerns and keep you informed about your shipment’s progress.</StyledP1>

<b>Preserving History, One Delivery at a Time</b>:
<StyledP1>When you purchase from Creative Antiqueology, you're not just buying an item – you're investing in a piece of history. Our delivery service is an extension of that commitment to preserving beauty and heritage.

Browse our collection today and let us bring the charm of timeless antiques right to your door.</StyledP1>



       </StyledP1>



        </Paper>
</Paper>





)}

export default Terms;